<template>
    <div class="page">
        <div class="header">
            <div class="btns">
                <el-button size="mini" @click="getData" icon="el-icon-refresh" class="hidden-sm-only hidden-sm-only">刷新
                </el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="危急值监控管理"/>
        </div>
        <div class="search">
            <el-form :inline="true" :model="search" size="small">
                <el-form-item label="工作单位" label-width="70px">
                    <cascader-company-name v-model="search.companyName"></cascader-company-name>
                </el-form-item>
                <el-form-item label="姓名">
                    <el-input v-model="search.patientName" placeholder="请输入" clearable/>
                </el-form-item>
                <el-form-item label="小屋名称">
                    <el-select v-model="search.healthHutName" placeholder="请选择" style="width:100%" filterable allow-create
                               default-first-option clearable>
                        <el-option :label="item.healthHutName" :value="item.healthHutName" v-for="(item, index) in hutList"
                                   :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="测量项目">
                    <el-input v-model="search.itemName" placeholder="请输入" clearable/>
                </el-form-item>
                <el-form-item label="危急级别">
                    <el-select v-model="search.crisisValueLevel" placeholder="请选择" clearable>
                        <el-option label="三级" value="3"></el-option>
                        <el-option label="二级" value="2"></el-option>
                        <el-option label="一级" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="处理状态">
                    <el-select v-model="search.reportStatus" placeholder="请选择" clearable>
                        <el-option label="已处理" value="1"></el-option>
                        <el-option label="未处理" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="报告时间">
                    <el-date-picker v-model="search.daterange" size="small" type="daterange" value-format="yyyy-MM-dd"
                                    range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData" icon="el-icon-search"
                               v-has="'weijizhi_search'">查询
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" stripe size="small" v-loading="loading" width="100%" height="100%" border
                          @selection-change="handleSelectionChange">
                    <!-- <el-table-column type="selection" width="55" align="center" /> -->
                    <el-table-column type="index" label="序号" align="center"
                                     :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                                     width="55"/>
                    <el-table-column prop="reportTime" label="报告上传时间" width="" show-overflow-tooltip/>
                    <el-table-column align="center" label="基本信息" width="160">
                        <template slot-scope="scope">
                            <div>
                                <span>{{ scope.row.patientName }}</span>
                                <span v-if="scope.row.gender == '2'"> 女 </span>
                                <span v-if="scope.row.gender == '1'"> 男 </span>
                                <span v-if="scope.row.age">{{ scope.row.age }} 岁</span>
                            </div>
                            <div>
                                <small > {{ isFullIdCard[scope.$index] ? scope.row.idCards : maskIdCardRegex(scope.row.idCards) }}</small>
                                <i class="el-icon-view" style="cursor: pointer;padding-left: 10px" @click="toggleIdCard(scope.$index)"></i>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="phoneNumber" label="手机号" width="" show-overflow-tooltip/>
                    <el-table-column prop="healthHutName" label="小屋名称" width="" show-overflow-tooltip/>
                    <el-table-column prop="deviceName" label="设备名称" width="" show-overflow-tooltip/>
                    <el-table-column prop="crisisValueLevel" label="危急级别" width="" align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-tag type="danger" v-if="scope.row.crisisValueLevel == '3'">三级</el-tag>
                            <el-tag type="warning" v-if="scope.row.crisisValueLevel == '2'">二级</el-tag>
                            <el-tag type="info" v-if="scope.row.crisisValueLevel == '1'">一级</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="reportStatus" label="处理状态" align="center" width="" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-tag type="danger" v-if="scope.row.reportStatus == '0'">未处理</el-tag>
                            <el-tag type="success" v-if="scope.row.reportStatus == '1'">已处理</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="itemName" label="测量项目" width="" show-overflow-tooltip/>
                    <el-table-column prop="itemValue" label="实际值" width="" show-overflow-tooltip/>
                    <el-table-column prop="reportNo" label="报告编号" width="" show-overflow-tooltip/>
                    <el-table-column prop="reportTime" label="报告时间" width="" show-overflow-tooltip/>
                    <el-table-column prop="dispose_time" label="处理时间" width="" show-overflow-tooltip >
                        <template slot-scope="scope">
                           <span>{{scope.row.dispose_time | datetime}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="dispose" label="处理过程" width="" show-overflow-tooltip >
                    </el-table-column>
                    <el-table-column prop="account_name" label="处理医生" width="" show-overflow-tooltip />
                    <el-table-column align="center" width="260" label="操作" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="handleUpdate(scope.row)" v-has="'weijizhi_view'">
                                查看报告
                            </el-button>
                            <el-button type="text" size="mini" @click="handleWechat(scope.row)" v-has="'weijizhi_wx'">
                                微信通知
                            </el-button>
                            <el-button type="text" size="mini" @click="handleChuLi(scope.row)" v-has="'weijizhi_cl'">
                                处 理
                            </el-button>
                            <el-button type="text" size="mini" @click="handleBeizhu(scope.row)" v-has="'weijizhi_bz'">
                                备 注
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                           :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                           @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
        </div>
        <data-form :visible="dialogVisible" :form="form" @close="dialogVisible = false" @update="getData"
                   ref="vmodel"></data-form>
        <wechat-form :visible="wechatformVisible" :form="form" @close="wechatformVisible = false" @update="getData"
                     ref="wechatvmodel"></wechat-form>
        <beizhu-form :visible="beizhuformVisible" :form="form" @close="beizhuformVisible = false" @update="getData"
                     ref="beizhuvmodel"></beizhu-form>
        <chuli-form :visible="chuliformVisible" :form="form" @close="chuliformVisible = false" @update="getData"
                     ref="beizhuvmodel"></chuli-form>
    </div>
</template>

<script>
    import dataForm from './widgets/dataform'
    import wechatForm from './widgets/wechatform'
    import beizhuForm from './widgets/beizhuform'
    import chuliForm from './widgets/chuliform'

    export default {
        name: 'weijizhi',
        path: '/main/xiaowu/weijizhi',
        components: {
            dataForm,
            wechatForm,
            beizhuForm,
            chuliForm
        },
        data() {
            return {
                loading: false,
                search: {daterange: []},
                table: {
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 20
                },
                dialogVisible: false,
                wechatformVisible: false,
                beizhuformVisible: false,
                chuliformVisible: false,
                form: {},
                isFullIdCard: [],
                hutList: []
            }
        },
        methods: {
            handleChuLi(row){
                this.form = JSON.parse(JSON.stringify(row))
                this.chuliformVisible = true
            },
            async getHutData() {
                try {
                    this.$http.get(`/hut/hut?page=1&pageSize=500`)
                        .then(resp => {
                            this.hutList = resp.data.data
                        }).catch(err => {
                        console.log(err)
                        this.$message.error(err.message)
                    })
                } catch (error) {
                    this.$message.error(error.message)
                    this.loading = false
                }
            },
            handleSelectionChange(val) {
                var that = this
            },
            maskIdCardRegex(idCard) {
                if(!idCard){
                    return
                }
                return idCard.replace(/^(.{4})(.*)(.{4})$/, function (match, p1, p2, p3) {
                    return p1 + p2.replace(/./g, '*') + p3;
                });
            },
            toggleIdCard(index) {
                // 切换显示状态
                this.$set(this.isFullIdCard, index, !this.isFullIdCard[index]);
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            handleUpdate(row) {
                this.form = JSON.parse(JSON.stringify(row))
                this.dialogVisible = true
            },
            handleWechat(row) {
                this.form = JSON.parse(JSON.stringify(row))
                this.wechatformVisible = true
            },
            handleBeizhu(row) {
                this.form = JSON.parse(JSON.stringify(row))
                this.beizhuformVisible = true
            },
            handleDone(row) {
                this.$confirm(`确认要处理 ${row.patientName} 的记录吗？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$http.post(`/hut/weijizhi/set_done`, row)
                        .then(resp => {
                            this.$message[resp.data.status](resp.data.msg)
                            if (resp.data.status == 'success') this.getData()
                        }).catch(err => {
                        this.$message.error(err.message)
                    }).finally(() => {
                        this.loading = false
                    })
                    this.loading = false
                }).catch(() => {
                });
            },
            async getData() {
                this.dialogVisible = false
                this.wechatformVisible = false
                this.beizhuformVisible = false
                this.chuliformVisible = false
                this.loading = true
                try {
                    this.$http.get(`/hut/weijizhi?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                        .then(resp => {
                            this.table.data = resp.data.data
                            this.table.total = resp.data.total
                            this.loading = false
                        }).catch(err => {
                        console.log(err)
                        this.loading = false
                        this.$message.error(err.message)
                    })
                } catch (error) {
                    this.loading = false
                    this.$message.error(error.message)
                }
            }
        },
        mounted() {
            this.getHutData()
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 5px !important;
            margin-bottom: 5px !important;
        }

        .btns {
            float: right;
        }
    }

    .main-content {
        flex: 1;
        position: relative;
    }

    .table-content {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .pager {
        padding-top: 10px;
        text-align: center;
    }
</style>
